<template>
    <div id="government-page">
        <section class="section hero top-section vh">
            <div class="container">
                <div class="hero-body columns">
                    <div class="column is-6 is-12-touch">
                        <div class="title l pb-3">Government Solutions</div>
                        <div class="subtitle">
                            Eduworks has performed Government-sponsored research and
                            advanced development since 2003 to create innovative solutions
                            to complex problems for clients in defense, national security,
                            and adjacent sectors. We apply emerging capabilities in AI
                            and machine learning to meet client requirements across
                            training and readiness application areas including air combat,
                            dismounted infantry, surface warfare, flying training,
                            medical care, and communications.
                        </div>
                        <div class="title is-size-4 mt-6">Quality, impact, and exceeding expectations are our success
                            metrics.</div>
                    </div>
                    <div class="column is-hidden-touch">
                        <img src="@/assets/Government/government-page-overlay.png"
                             alt />
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-1">
                        <a href="#section2">
                            <i class="fas fa-chevron-down fa-3x has-text-primary scroll-down-arrow hide-mobile"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section id="section2"
                 class="hero is-light is-medium">
            <div class="hero-body">
                <div class="columns is-centered">
                    <div class="column is-6 p-6"
                         style="border: 3px solid #be2a2f;">
                        <h3 class="title is-3 has-text-centered pb-5">Quality is built on our team.</h3>Eduworks staff
                        includes a highly-experienced research and engineering team composed of innovators in the training
                        and educational technology community and world-class software developers with expertise across a
                        broad set of application domains.
                        Our research team has published extensively, served on editorial boards of prestigious journals,
                        held faculty positions at top-ranked institutions, served on committees for leading professional
                        conferences, held leadership positions in standards development organizations, led multiple
                        successful R&D and software development projects, and participated as advisors to Government
                        agencies and national initiatives.
                    </div>
                </div>
            </div>
        </section>

        <section id="naics-codes"
                 class="hero">
            <div class="hero-body">
                <div class="columns is-centered">
                    <div class="column is-6">
                        <h3 class="title is-2 mt-6">Contracting Vehicles</h3>
                        <h6 class="title is-6">Eduworks is a small business DCAA performer with established rates for
                            government contracts.</h6>
                        <p>
                            Eduworks has contracting vehicles available through third party
                            prime contractors on the following vehicles, and directly under SBIR Phase III:
                        </p>
                        <div class="columns">
                            <div class="column">
                                <ul class="fa-ul mt-3">
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>OPM Knowledge Portal
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>Air Force Strategic Transformation Support
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>Army Virtual Learning Environment
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>SeaPort Next Generation
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>AETC Course Development and Delivery
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>SBIR
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>CDAO Tradewinds
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>Army ITES-SW2
                                    </li>
                                    <li>
                                        <span class="fa-li">
                                            <i class="fas fa-check-square has-text-warning"></i>
                                        </span>NASA SEWP
                                    </li>
                                </ul>
                                <!-- <div class="columns">
                                    <div class="column"><img src=""></div>
                                    <div class="column"></div>
                                </div> -->
                            </div>
                            <div class="column is-narrow">
                                <div class="has-text-weight-bold p-2"
                                     style="background-color: #eee; border-left: 3px solid #be2a2f;">
                                    DUNS Number: 111494303 <br />
                                    CAGE Code: 37UG2
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 p-4 has-background-light">
                            <div class="title is-6 mb-2">NAICS Codes</div>
                            <ul class="is-size-6">
                                <li>511210 Software Publishers</li>
                                <li>541330 Engineering Services</li>
                                <li>541511 Custom Computer Programming Services (Primary)</li>
                                <li>541512 Computer Systems Design Services</li>
                                <li>541519 Other Computer Related Services</li>
                                <li>541612 Human Resources Consulting Services</li>
                                <li>541690 Other Scientific and Technical Consulting Services</li>
                                <li>541715 Research and Development in the Physical, Engineering, and Life Sciences (except
                                    Nanotechnology and Biotechnology)</li>
                                <li>611710 Educational Support Services</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <hr />

        <section class="section hero is-small">
            <div class="is-flex is-align-items-baseline is-justify-content-center">
                <p class="title is-4 mr-4">Want to know more?</p>
                <router-link :to="{ name: 'Contact' }"
                             class="button is-primary is-radiusless has-text-weight-bold primary-button">
                    Contact Us
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Government',
    components: {},
    data: function () {
        return {}
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'Government Solutions',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'Eduworks | %s'
    },
    methods: {},
    mounted() { },
    computed: {}
}
</script>